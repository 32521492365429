/* eslint-disable import/extensions */
/* eslint-disable import/no-extraneous-dependencies */
import "@/assets/scss/swiper.scss";
import { Loading } from "@/components";
import HistoryComponent from "@/components/common/History";
import BaseInfoComponent from "@/components/pages/ProductDetails/BaseInfo";
import DescriptionComponent from "@/components/pages/ProductDetails/Description";
import DetailsCarouselComponent from "@/components/pages/ProductDetails/DetailsCarousel";
import PolicyComponent from "@/components/pages/ProductDetails/Policy";
import QAComponent from "@/components/pages/ProductDetails/QA";
import RoutinesComponent from "@/components/pages/ProductDetails/Routines";
import TableInformation from "@/components/pages/ProductDetails/TableInformation";
import VolumeComponent from "@/components/pages/ProductDetails/Volume";
import useProductDetailsQueryData from "@/hooks/ReactQuery/productDetails.query";
import { CartState, IICartStateRecoil, addToCart } from "@/recoil/cartState";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilState, useSetRecoilState } from "recoil";
import toast from "react-hot-toast";
import { Tab, initTE } from "tw-elements";
import FeedbackComment from "@/components/pages/ProductDetails/FeebackComment";
import { useEffect, useState } from "react";
import viewedProductsState, {
  updateViewedProducts,
} from "@/recoil/viewedProductsState";
import useProductsQuery from "@/hooks/ReactQuery/products.query";
import Breadcrumb from "@/components/common/Breadcrumb";
import routes from "@/utils/routes";
import loadingScreenState from "@/recoil/loadingScreenState";
import useWindowSize from "@/hooks/useWindowSize";
import queryString from "query-string";
import { TRACKING_KEYS } from "@/utils/tracking";
import trackingApi from "@/api/trackingAPI";
import NewProduct from "@/components/common/NewProduct";
import useQueryQAProduct from "@/hooks/ReactQuery/QAProduct.query";
import VouchersComponent from "@/components/pages/ProductDetails/Vouchers";
import svgs from "@/assets/svgs";
import { useRootLayoutContext } from "@/layouts/RootLayout";
import { IVoucherState } from "@/recoil/selectedVoucherState";
import voucherApi from "@/api/voucherApi";
import { BaseData } from "@/type/base/baseData";
import { VouchersShopType } from "@/type/response/vouchers";
import selectedVouchersState from "@/recoil/selectedVoucherState";
// Initialization for ES Users

initTE({ Tab });

export default function ProductDetail() {
  const { slug: slugParams } = useParams<string>();
  const { t } = useTranslation();
  const setCart = useSetRecoilState<IICartStateRecoil>(CartState);
  const navigate = useNavigate();
  const size = useWindowSize();

  const {
    isLoading,
    product,
    percent,
    rating,
    variationProd,
    variations,
    productId,
    baseDataProduct,
  } = useProductDetailsQueryData(slugParams as string);
  const { productFaqs, isLoading: isQALoading } = useQueryQAProduct(
    productId as string
  );
  const [shopVouchers, setShopVouchers] = useState<
    BaseData<VouchersShopType>[]
  >([]);
  const vouchersShopList = shopVouchers.filter(
    (item) => item?.attributes?.isShop === true
  );

  const [selectedVoucher] = useRecoilState<IVoucherState[]>(
    selectedVouchersState
  );
  useEffect(() => {
    const fetchVouchers = async () => {
      await voucherApi
        .getAllVoucher()
        .then((res) => {
          setShopVouchers(res?.data?.data);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    fetchVouchers();
  }, []);

  const [viewedProduct, setViewedProductsState] =
    useRecoilState(viewedProductsState);
  const searchParams = viewedProduct
    .map((item, index) => `filters[id][${index}]=${item}`)
    .join("&");
  const {
    products: viewedProducts,
    isLoading: isQueryViewedProductLoading,
    allProducts,
  } = useProductsQuery(searchParams);
  const handleSetIsLoading = useSetRecoilState(loadingScreenState);
  const { setIsLoadingDone } = useRootLayoutContext();

  const productsForYou = allProducts?.sort(
    (a, b) =>
      Number(a?.attributes?.totalPurchase) -
      Number(b?.attributes?.totalPurchase)
  );

  const breadcrumbItems =
    size.width < 768
      ? [
          {
            href: routes.HOME,
            title: "Trang chủ",
          },
          {
            title: "...",
          },
          {
            href: `${routes.PRODUCT}/${slugParams}`,
            title: product?.name || product?.shortName,
          },
        ]
      : [
          {
            href: routes.HOME,
            title: "Trang chủ",
          },
          {
            href: routes.PRODUCT,
            title: "Sản phẩm",
          },
          {
            href: `${routes.PRODUCT}/${slugParams}`,
            title: product?.name || product?.shortName,
          },
        ];
  const handleBuyWithVoucher = () => {
    handleAddToCart();
    navigate(routes.CART);
  };
  const handleAddToCart = async () => {
    if (productId !== undefined) {
      const productFormat = {
        id: productId,
        name: product?.name || product?.shortName || "",
        image: product?.avatar?.data?.attributes?.url || "",
        price: product?.price || "",
        pricePromotion: product?.pricePromotion || "",
        flashSalePrice: product?.flashSalePrice || "",
        slug: product.slug,
      };
      setCart((prevCart) => ({
        ...prevCart,
        cartProducts: addToCart(prevCart.cartProducts, productFormat),
      }));

      const utmSource = sessionStorage.getItem("utm_source") || "google";
      await trackingApi
        .trackAddToCart([productId as number], utmSource)
        .catch((e) => console.log(e));
      await trackingApi
        .updateProductEfficiency([productId as number], utmSource)
        .catch((e) => console.log(e));

      toast.success(t("addCartSuccess"));
    } else {
      toast.error(t("addCartFail"));
    }
  };

  useEffect(() => {
    handleSetIsLoading(true);

    // Get the current URL query string
    const queryStr = location.search;

    // Parse the query string using URLSearchParams
    const parsedParams: queryString.ParsedUrl = queryString.parseUrl(queryStr);

    // Loop through the keys and save the corresponding values to sessionStorage
    TRACKING_KEYS.forEach((key) => {
      // Check if the parameter exists in the parsed parameters
      if (parsedParams.query[key]) {
        if (!sessionStorage.getItem("tracking-used")) {
          sessionStorage.setItem("tracking-used", "true");
          sessionStorage.setItem("tracking-count", "0");
        }
        // Get the parameter value
        const value = parsedParams.query[key];

        // Save the key-value pair to sessionStorage
        sessionStorage.setItem(key, value as string);
      }
    });
  }, []);

  useEffect(() => {
    if (productId) {
      setViewedProductsState((prev) => updateViewedProducts(prev, productId));
    }
  }, [productId]);

  useEffect(() => {
    if (!isLoading) {
      setIsLoadingDone(!isLoading);
    }
  }, [isLoading]);

  if (isLoading) {
    return <Loading />;
  }

  // handle error product undefined
  if (product == undefined) {
    navigate("/404");
  }

  console.log("selected voucher", selectedVoucher);
  return (
    <>
      <Breadcrumb items={breadcrumbItems} />

      <div className="w-full flex flex-col gap-[8px] bg-[#F7F7F7] items-center relative">
        <div className="bg-[#FFF] w-full flex justify-center">
          <div className="max-w-[1440px]  grid grid-cols-12 gap-[32px] px-0 md:py-[32px] md:px-[64px] lg:px-[160px]">
            <div className="col-span-full md:col-span-6 md:sticky md:top-[165px] h-fit flex flex-col gap-[32px]">
              {/* layout for desktop */}
              <section className="product-details__carousel w-full">
                {product?.gallery?.data && product?.avatar?.data && (
                  <DetailsCarouselComponent
                    imageMain={product?.avatar?.data}
                    imageChildren={product?.gallery?.data}
                  />
                )}
              </section>

              <section className="hidden md:block">
                <TableInformation productId={productId as string} />
              </section>
            </div>
            <div className="col-span-full md:col-span-6 flex flex-col gap-[24px] px-[16px] md:px-0">
              <BaseInfoComponent
                productName={product?.name || product?.shortName}
                productPrice={product?.price}
                productSalePrice={product?.pricePromotion}
                percent={percent ?? 0}
                rating={rating || 0}
                productPurchase={product?.totalPurchase || 0}
              />
              <div className="md:hidden">
                <VouchersComponent vouchers={vouchersShopList} />
              </div>
              <VolumeComponent
                variationProd={variationProd}
                id={productId.toString()}
                variations={variations}
              />
              <div className="md:hidden">
                <PolicyComponent />
              </div>
              <div className="hidden md:flex flex-col border border-[#E6E6E6] rounded-[16px] bg-[#F0F0F0]">
                <div className="p-[24px]">
                  <PolicyComponent />
                </div>
                <div className="border-t border-[#E6E6E6]"></div>
                <div className="p-[24px]">
                  <VouchersComponent vouchers={vouchersShopList} />
                </div>
              </div>
              <section className="hidden md:flex justify-center items-center gap-[16px]">
                <button
                  className="group/addCartBtn flex-1 flex justify-center items-center p-[16px_28px] border border-[#000] rounded-[24px] bg-[#FFFFFF] box-border h-[51px] hover:border-[#FF9900] transition-colors"
                  type="submit"
                  onClick={() => handleAddToCart()}
                >
                  <p className="text-[16px] font-[600] text-[#000] leading-[19px] group-hover/addCartBtn:text-[#FF9900]">
                    {t("addToCart")}
                  </p>
                </button>

                <button
                  className="group/buyVoucherBtn flex-1 flex justify-center items-center p-[16px_28px] border border-[#000] rounded-[24px] bg-[#000] box-border h-[51px] "
                  type="submit"
                  onClick={() => handleBuyWithVoucher()}
                >
                  <p className="text-[16px] font-[600] text-[#FFF] leading-[19px] group-hover/buyVoucherBtn:text-[#FF9900]">
                    {selectedVoucher?.length > 0
                      ? "Mua với voucher"
                      : "Mua ngay"}
                  </p>
                </button>
              </section>

              {/* <section className="md:hidden">
                <TableInformation productId={productId as string} />
              </section> */}

              <section className="hidden md:block">
                <RoutinesComponent
                  productId={productId as string}
                  hasShadow={true}
                />
              </section>
            </div>
          </div>
        </div>

        <section className="md:hidden w-full bg-[#FFF]">
          <TableInformation productId={productId as string} />
        </section>

        {product?.description && (
          <section className="md:hidden w-full bg-[#FFF]">
            <DescriptionComponent descriptions={product?.description} />
          </section>
        )}

        <div className="hidden bg-[#FFF] w-full md:flex justify-center">
          <div className="max-w-[1440px] px-[16px] md:px-[64px] lg:px-[160px] h-full">
            <div className="grid grid-cols-[1fr_345px] gap-[8px] w-full bg-[#F7F7F7] relative h-full">
              <div className="p-[32px] pl-0 flex flex-col gap-[32px] bg-[#FFFFFF] h-full">
                {product?.description && (
                  <DescriptionComponent descriptions={product?.description} />
                )}

                {/* This is section feedback and comment */}
                <FeedbackComment
                  product={product}
                  productId={productId.toString()}
                />
              </div>

              <div className="p-[32px] pr-0 bg-[#FFFFFF] h-full flex flex-col gap-[16px] flex-grow">
                <p className="text-[18px] font-[400] leading-[25.2px]">
                  Dành cho bạn
                </p>
                <div className="flex flex-col gap-[24px] flex-grow">
                  {productsForYou?.slice(0, 5).map((product, index) => {
                    return (
                      <NewProduct
                        product={product}
                        thumbType={"horizontal"}
                        contentPage={"purchase"}
                        key={index}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>

        <section className="md:hidden w-full">
          <RoutinesComponent productId={productId as string} hasShadow={true} />
        </section>

        <section className="md:hidden w-full bg-[#FFF]">
          <FeedbackComment product={product} productId={productId.toString()} />
        </section>

        {viewedProducts!.length > 0 && (
          <div className="bg-[#FFF] w-full flex justify-center py-[32px]">
            <section className="max-w-[1440px] px-[16px] md:px-[64px] lg:px-[160px] bg-[#FFF] w-full">
              <HistoryComponent
                productsHistory={viewedProducts!}
                isLoading={isQueryViewedProductLoading}
              />
            </section>
          </div>
        )}

        {productFaqs?.length > 0 && (
          <div className="bg-[#FFF] w-full flex justify-center py-[32px]">
            <div className="max-w-[1440px] px-[16px] md:px-[64px] lg:px-[160px] bg-[#FFF]">
              <QAComponent productFaqs={productFaqs} isLoading={isQALoading} />
            </div>
          </div>
        )}

        {/* <section className="product-details__more-voucher my-8 md:w-full">
        <VoucherComponent />
      </section> */}

        <div className="md:hidden fixed bottom-0 left-0 w-full flex items-center gap-[24px] p-[8px_16px] shadow-[0_0_27px_0_rgba(0, 0, 0, 0.12)] bg-[#FFF] z-[50]">
          <div
            className="flex flex-col items-center gap-[4px]"
            onClick={() =>
              navigate(routes.CHAT, { state: { baseDataProduct } })
            }
          >
            <img
              src={svgs.advise}
              alt=""
              className="size-[24px] object-cover aspect-square"
            />
            <p className="text-[11px] font-[400]">Tư vấn</p>
          </div>

          <div
            className="flex flex-col items-center gap-[4px]"
            onClick={() => handleAddToCart()}
          >
            <img
              src={svgs.cartProductDetail}
              alt=""
              className="size-[24px] object-cover aspect-square"
            />
            <p className="text-[11px] font-[400]">Thêm vào giỏ</p>
          </div>

          <button
            className="flex-1 p-[10px_20px] rounded-[24px] bg-[#000] text-center"
            onClick={() => handleBuyWithVoucher()}
          >
            <p className="text-[14px] font-[600] text-[#F0F0F0]">
              {selectedVoucher?.length > 0 ? "Mua với voucher" : "Mua ngay"}
            </p>
          </button>
        </div>
      </div>
    </>
  );
}
