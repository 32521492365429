import flashSaleApi from '@/api/flashSaleApi';
import { BaseData } from '@/type/base/baseData';
import { FlashSaleType, ProductFlashSaleType } from '@/type/response/flashSale';
import keysReactQuery from '@/utils/keys';
import { getTimeNow } from '@/utils/time';
import { useQuery } from '@tanstack/react-query';
import moment from "moment";
import { useEffect, useState } from "react";

const MINUTES = 0;

export function useFlashSaleQueryData() {
  const timeNow = getTimeNow();
  const { data, isLoading, isFetching, error, isError } = useQuery({
    queryKey: [keysReactQuery.FLASH_SALE],
    queryFn: () => flashSaleApi.getAll(timeNow),
    staleTime: MINUTES * 60 * 1000,
  });

  const flashSale: BaseData<FlashSaleType> = data?.data
    ?.data?.[0] as BaseData<FlashSaleType>;
  const productsFlashSale: BaseData<ProductFlashSaleType>[] =
    flashSale?.attributes.products?.data ?? [];

  return {
    flashSale,
    productsFlashSale,
    isLoading,
    isFetching,
    error,
    isError,
  };
}

export const useCheckFlashSalesAtTimes = (body: { ids: number[] }) => {
  const [currentTime, setCurrentTime] = useState(moment());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(moment());
    }, 60 * 1000); // Update current time every minute

    return () => clearInterval(intervalId); // Cleanup the interval on component unmount
  }, []);

  const { data, refetch } = useQuery({
    queryKey: ['checkFlashSalesAtTimes'],
    queryFn: () => flashSaleApi.checkFlashSales(body),
    refetchInterval: false, // Disable the default refetch interval
  });

  useEffect(() => {
    const hours = currentTime.hours();
    const minutes = currentTime.minutes();

    // Check if the current time matches any of the specified times
    if (
      (hours === 0 && minutes === 0) /* 0h - 9h */ ||
      (hours === 9 && minutes === 0) /* 9h - 13h */ ||
      (hours === 13 && minutes === 0) /* 13h - 15h */ ||
      (hours === 15 && minutes === 0) /* 15h - 17h */ ||
      (hours === 17 && minutes === 0) /* 17h - 19h */ ||
      (hours === 19 && minutes === 0) /* 19h - 21h */ ||
      (hours === 21 && minutes === 0) /* 21h - 0h */
    ) {
      // Refetch data at the specified times
      refetch();
    }
  }, [currentTime, refetch]);

  const productIdsInFlashSales = data?.data || []

  localStorage.setItem("flashSaleProductIdsState", JSON.stringify(productIdsInFlashSales))

  return { data, productIdsInFlashSales };
};
