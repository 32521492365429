import svgs from "@/assets/svgs";
import { BaseData } from "@/type/base/baseData";
import { ProductsResponse } from "@/type/response/products";
import { RoutineProductType } from "@/type/response/routine";
import { formatMoney } from "@/utils/data";
import { formatPriceToVnd } from "@/utils/formatPriceToVnd";
import { Rate } from "antd";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { IThumbTypeProps } from "./Thumb";
import { BaseProductData } from "@/type/base/baseProduct";
import { Link } from "react-router-dom";
import routes from "@/utils/routes";

export interface IContentPageProps {
  contentPage:
    | "home"
    | "products"
    | "purchase"
    | "routine"
    | "chat"
    | "product-detail"
    | "feedback";
  useSpecialName?: boolean;
  showPriceHorizontal?: boolean;
  productInCart?: number; // using with 'purchase'
  showCompare?: boolean; // using with 'purchase' and 'home'
  orderNumber?: number; //using with 'routine'
  routineProduct?: BaseData<RoutineProductType>; //using with 'routine'
}

interface IContentProps extends IContentPageProps, IThumbTypeProps {
  product: BaseData<ProductsResponse | BaseProductData>;
}

const StyledRate = styled(Rate)`
  &.ant-rate {
    .ant-rate-star {
      margin-inline-end: 0px !important;
      svg {
        font-size: 16px !important;
      }
    }
  }
`;

const Content = (props: IContentProps) => {
  const {
    product,
    thumbType = "primary",
    useSpecialName = false,
    showPriceHorizontal = false,
    contentPage = "home",
    productInCart,
    showCompare = false,
    orderNumber,
    routineProduct,
  } = props;

  const { t } = useTranslation();

  const infoProduct = product?.attributes;

  const price = parseInt(infoProduct?.price);
  const flashSalePrice = parseInt(infoProduct?.flashSalePrice);
  const pricePromotion = parseInt(infoProduct?.pricePromotion);

  const isSale =
    (pricePromotion != null && pricePromotion !== price) ||
    ["flashsale", "chat"].includes(thumbType);
  const pricePromo =
    ["flashsale", "chat"].includes(thumbType) && flashSalePrice
      ? flashSalePrice
      : pricePromotion || price;

  const originPrice = formatPriceToVnd(price);
  const salePrice = formatPriceToVnd(pricePromo);
  const diffPrice = formatMoney(price - pricePromo);

  if (contentPage === "chat") {
    return (
      <div className="flex flex-col gap-[4px]">
        <p className="text-ellipsis line-clamp-2 text-[14px] font-[400] h-[40px] leading-[19.6px]">
          {useSpecialName
            ? infoProduct?.shortName || infoProduct?.name
            : infoProduct?.name}
        </p>

        <div className="h-[20px]">
          <p className="text-[14px] font-[500] text-[#FF9900] leading-[19.6px]">
            {isSale ? salePrice : originPrice}
          </p>
        </div>
      </div>
    );
  }

  if (contentPage === "routine") {
    return (
      <Link
        className="flex flex-col gap-[10px] md:gap-[8px]"
        to={`${routes.PRODUCT}/${product?.attributes?.slug}`}
      >
        <div className="hidden md:flex justify-between items-end gap-[10px]">
          <p className="text-[40px] font-[700] leading-[67.2px]">
            {orderNumber || 1}
          </p>
          <div className="flex flex-col gap-[4px] items-end h-full">
            <div className="text-[14px] font-[500] uppercase line-clamp-2 text-ellipsis text-right leading-[20px]">
              {routineProduct?.attributes?.name}
            </div>
            <p className="text-[13px] font-[400] text-right">
              {formatPriceToVnd(
                parseInt(routineProduct?.attributes?.price || "0")
              )}
            </p>
            <div className="border-b w-full border-[#000]"></div>
          </div>
        </div>

        <p className="hidden text-[14px] font-[400] leading-[19.6px] text-ellipsis md:line-clamp-5 text-justify">
          {routineProduct?.attributes?.shortDescription}
        </p>

        <p className="md:hidden text-[14px] font-[500] uppercase line-clamp-2">
          {routineProduct?.attributes?.name}
        </p>

        <div className="md:hidden border-b w-full border-[#000]"></div>

        <div className="flex flex-col gap-[8px] md:hidden">
          <div className="flex justify-between">
            <p className="text-[11px] font-[400] flex-1 text-ellipsis line-clamp-2">
              Công dụng
            </p>
            <p className="text-[11px] font-[400] flex-1 text-right text-ellipsis line-clamp-2">
              Tẩy tế bào chết
            </p>
          </div>

          <div className="flex justify-between">
            <p className="text-[11px] font-[400] flex-1 text-ellipsis line-clamp-2">
              Giá bán
            </p>
            <p className="text-[11px] font-[700] flex-1 text-right text-ellipsis line-clamp-2">
              {formatPriceToVnd(
                parseInt(routineProduct?.attributes?.price || "0")
              )}
            </p>
          </div>
        </div>
      </Link>
    );
  }

  if (contentPage === "purchase") {
    return (
      <Link
        className="flex flex-col gap-[8px]"
        to={`${routes.PRODUCT}/${product?.attributes?.slug}`}
      >
        <p className="text-ellipsis text-black line-clamp-2 text-[14px] md:text-[16px] font-[500] h-[34px] md:h-[38px] leading-[17px] md:leading-[20px]">
          {useSpecialName
            ? infoProduct?.shortName || infoProduct?.name
            : infoProduct?.name}
        </p>
        {!showCompare && (
          <div className="h-[15px] md:h-[17px] md:hidden">
            <p className="text-[14px] font-[400] text-[#545454]">
              {infoProduct?.variationValue &&
                infoProduct?.productUnit?.data?.attributes?.name && (
                  <>
                    {infoProduct?.variationValue}{" "}
                    {infoProduct?.productUnit?.data?.attributes?.name}
                  </>
                )}
            </p>
          </div>
        )}
        <div className="flex flex-col gap-[4px]">
          <div className="h-[15px] md:h-[17px]">
            {isSale && (
              <p className="text-[12px] md:text-[14px] font-[400] line-through text-[#818080]">
                {isSale && originPrice === salePrice ? "" : originPrice}
              </p>
            )}
          </div>
          <div className="h-[17px] md:h-[19px]">
            <div className="flex justify-between items-center">
              <p className="text-[14px] md:text-[16px] font-[400] md:font-[500] text-[#FF9900]">
                {isSale ? salePrice : originPrice}
              </p>
              <p className="text-[12px] md:text-[14px] text-[#818080] font-normal md:hidden">
                {/* {"x "} */}
                {/* {productInCart || 0} */}
              </p>
            </div>
          </div>
        </div>
        <div className="h-[15px] md:h-[17px] hidden md:block">
          <p className="text-[12px] md:text-[14px] text-[#818080] font-normal ">
            {/* {"x "} */}
            {/* {productInCart || 0} */}
          </p>
        </div>
        {showCompare && (
          <div className="h-[15px] flex flex-nowrap items-center">
            <img
              src={svgs.doubleChevronDown}
              width={16}
              height={16}
              className="p-[4px]"
            />
            <p className="text-[12px] text-[#FF9900] font-normal truncate">
              Giảm {diffPrice} so với giá niêm yết
            </p>
          </div>
        )}
        {!showCompare && (
          <div className="h-[15px] md:h-[17px] md:hidden">
            <p className=" text-[12px] md:text-[14px] text-[#818080] font-normal ">
              {t("countSold")}{" "}
              {infoProduct?.totalPurchase !== null
                ? infoProduct?.totalPurchase
                : 0}
            </p>
          </div>
        )}
      </Link>
    );
  }

  if (contentPage === "products") {
    return (
      <Link
        className="flex flex-col gap-[8px]"
        to={`${routes.PRODUCT}/${product?.attributes?.slug}`}
      >
        <p className="text-ellipsis text-black line-clamp-2 text-[14px] md:text-[16px] font-[500] h-[34px] md:h-[38px] leading-[17px] md:leading-[20px]">
          {useSpecialName
            ? infoProduct?.shortName || infoProduct?.name
            : infoProduct?.name}
        </p>
        <div className="h-[15px] md:h-[17px]">
          <p className="text-[14px] font-[400] text-[#545454]">
            {infoProduct?.variationValue &&
              infoProduct?.productUnit?.data?.attributes?.name && (
                <>
                  {infoProduct?.variationValue}{" "}
                  {infoProduct?.productUnit?.data?.attributes?.name}
                </>
              )}
          </p>
        </div>
        <div className="flex gap-[8px] flex-col xl:flex-row-reverse items-start xl:items-center justify-start xl:justify-end">
          <div className="h-[15px] md:h-[17px]">
            {isSale && (
              <p className="text-[12px] md:text-[14px] font-[400] line-through text-[#818080]">
                {isSale && originPrice === salePrice ? "" : originPrice}
              </p>
            )}
          </div>
          <div className="h-[17px] md:h-[19px]">
            <p className="text-[14px] md:text-[16px] font-[400] md:font-[500] text-[#FF9900] leading-[17px] md:leading-[20px]">
              {isSale ? salePrice : originPrice}
            </p>
          </div>
        </div>
        <div className="h-[16px]">
          <StyledRate
            disabled
            defaultValue={infoProduct?.rating || 5}
            style={{ color: "#FF9900", fontSize: "16px" }}
            className="red"
            allowHalf
          />
        </div>
        <div className="h-[15px] md:h-[17px]">
          <p className=" text-[12px] md:text-[14px] text-[#818080] font-normal ">
            {t("countSold")}{" "}
            {infoProduct?.totalPurchase !== null
              ? infoProduct?.totalPurchase
              : 0}
          </p>
        </div>
      </Link>
    );
  }

  if (contentPage === "product-detail") {
    return (
      <Link
        className="flex flex-col gap-[8px]"
        to={`${routes.PRODUCT}/${product?.attributes?.slug}`}
      >
        <p className="text-ellipsis line-clamp-2 text-[14px] md:text-[16px] font-[500] h-[34px] md:h-[40px] leading-[17px] md:leading-[20px]">
          {useSpecialName
            ? infoProduct?.shortName || infoProduct?.name
            : infoProduct?.name}
        </p>
        <div
          className={clsx(
            "flex",
            showPriceHorizontal
              ? "gap-[8px] flex-row-reverse items-center justify-end"
              : "flex-col gap-[2px]"
          )}
        >
          <div className="h-[15px] md:h-[17px]">
            {isSale && (
              <p className="text-[12px] md:text-[14px] font-[400] line-through text-[#818080]">
                {isSale && originPrice === salePrice ? "" : originPrice}
              </p>
            )}
          </div>
          <div className="h-[17px] md:h-[19px]">
            <p className="text-[14px] md:text-[16px] font-[400] md:font-[500] text-[#000000] leading-[17px] md:leading-[20px]">
              {isSale ? salePrice : originPrice}
            </p>
          </div>
        </div>
      </Link>
    );
  }

  if (contentPage === "feedback") {
    return (
      <Link
        className="w-full flex flex-col justify-start items-start"
        to={`${routes.PRODUCT}/${product?.attributes?.slug}`}
      >
        <p className="text-ellipsis text-black line-clamp-2 text-[14px] md:text-[16px] font-[500] mb-[8px] md:mb-[15px] leading-[20px]">
          {useSpecialName
            ? infoProduct?.shortName || infoProduct?.name
            : infoProduct?.name}
        </p>
        <div className="w-full md:w-fit flex flex-col-reverse md:flex-row gap-[4px] md:gap-[8px] mb-[8px]">
          <div className="w-full h-[17px] md:h-[19px]">
            <div className="w-full flex justify-between items-center">
              <p className="text-[14px] md:text-[16px] md:font-[500] text-black font-[500] leading-[19.36px]">
                {isSale ? salePrice : originPrice}
              </p>
              <p className="text-[14px] md:text-[14px] text-[#545454] font-normal block md:hidden">
                {"x "}
                {productInCart || 0}
              </p>
            </div>
          </div>
          <div>
            {isSale && (
              <p className="text-[14px] font-[400] line-through text-[#818080] leading-[16.94px]">
                {originPrice}
              </p>
            )}
          </div>
        </div>
        <div className="h-[15px] hidden md:block">
          <p className="text-[14px] md:text-[14px] text-[#545454] leading-[16.94px] font-normal ">
            {"x "}
            {productInCart || 0}
          </p>
        </div>
      </Link>
    );
  }

  return (
    <Link
      className="flex flex-col gap-[8px]"
      to={`${routes.PRODUCT}/${product?.attributes?.slug}`}
    >
      <p className="text-ellipsis line-clamp-2 text-[14px] md:text-[16px] font-[500] h-[34px] md:h-[40px] leading-[17px] md:leading-[20px]">
        {useSpecialName
          ? infoProduct?.shortName || infoProduct?.name
          : infoProduct?.name}
      </p>
      <div
        className={clsx(
          "flex",
          showPriceHorizontal
            ? "gap-[8px] flex-col xl:flex-row-reverse  items-start xl:items-center justify-start xl:justify-end"
            : "flex-col gap-[2px]"
        )}
      >
        <div className="h-[15px] md:h-[17px]">
          {isSale && (
            <p className="text-[12px] md:text-[14px] font-[400] line-through text-[#818080]">
              {isSale && originPrice === salePrice ? "" : originPrice}
            </p>
          )}
        </div>
        <div className="h-[17px] md:h-[19px]">
          <p className="text-[14px] md:text-[16px] font-[400] md:font-[500] text-[#FF9900] leading-[17px] md:leading-[20px]">
            {isSale ? salePrice : originPrice}
          </p>
        </div>
      </div>
      {showCompare ? (
        <div className="h-[30px] md:h-[36px]">
          <p className=" text-[10px] md:text-[12px] text-[#FF9900] font-normal text-ellipsis line-clamp-2">
            Tiết kiệm {diffPrice} so với giá niêm yết
          </p>
        </div>
      ) : (
        <div className="h-[30px] md:h-[36px]">
          <p className=" text-[12px] md:text-[14px] text-[#818080] font-normal text-ellipsis line-clamp-2">
            {t("countSold")}{" "}
            {infoProduct?.totalPurchase !== null
              ? infoProduct?.totalPurchase
              : 0}
          </p>
        </div>
      )}
    </Link>
  );
};

export default Content;
