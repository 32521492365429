import trackingApi from "@/api/trackingAPI";
import images from "@/assets/images";
import svgs from "@/assets/svgs";
import DecreaseQuantity from "@/assets/svgs/custom/DecreaseQuantity";
import IncreaseQuantity from "@/assets/svgs/custom/IncreaseQuantity";
import { cartHeaderState } from "@/recoil/cartHeaderState";
import {
  CartState,
  decreaseCartProduct,
  deleteCartProduct,
  ICartProduct,
  IICartStateRecoil,
  increaseCartProduct,
} from "@/recoil/cartState";
import { formatPriceToEN } from "@/utils/formatPriceToEN";
import { formatPriceToVnd } from "@/utils/formatPriceToVnd";
import { Popconfirm } from "antd";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import BasicModal from "../Modal";
import routes from "@/utils/routes";
import productApi from "@/api/productApi";

interface IOrderCartProps {
  orderItem: ICartProduct;
  handleOutStock: (id: number) => void;
  handleRemoveOutStock: (id: number) => void;
  hasTrashIcon?: boolean;
  inCart?: boolean;
}

const OrderCart = (props: IOrderCartProps) => {
  const {
    orderItem,
    handleOutStock,
    handleRemoveOutStock,
    hasTrashIcon = true,
    inCart = false,
  } = props;
  const slugProduct = orderItem.data.slug;
  const [cart, setCart] = useRecoilState<IICartStateRecoil>(CartState);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [isDecreasePopconfirmOpen, setIsDecreasePopconfirmOpen] =
    useState<boolean>(false);
  const isCartHeaderOpen = useRecoilValue(cartHeaderState);

  const [isStock, setIsStock] = useState<boolean>(true);
  useEffect(() => {
    setIsStock(true);
    const fetchData = async () => {
      const responseData = await productApi.getBySlug(slugProduct);
      const productData = responseData.data.data;
      if (Number(productData?.attributes?.stock) === 0) {
        setIsStock(false);
        handleOutStock(Number(orderItem.data.id));
      }
    };
    fetchData();
  }, [slugProduct]);
  const handleDecrease = () => {
    if (orderItem.quantity === 1 && !isDecreasePopconfirmOpen) {
      setIsDecreasePopconfirmOpen(true);
      return;
    }
    const cartUpdate = decreaseCartProduct(cart.cartProducts, orderItem.id);
    setCart((prev) => ({ ...prev, cartProducts: cartUpdate }));
    setIsDecreasePopconfirmOpen(false);
  };

  const handleIncrease = async () => {
    const cartUpdate = increaseCartProduct(cart.cartProducts, orderItem.id);
    setCart((prev) => ({ ...prev, cartProducts: cartUpdate }));

    const utmSource = sessionStorage.getItem("utm_source") || "google";
    await trackingApi.trackAddToCart([orderItem.id as number], utmSource);
    await trackingApi.updateProductEfficiency(
      [orderItem.id as number],
      utmSource
    );
  };

  const handleRemove = () => {
    const cartUpdate = deleteCartProduct(cart.cartProducts, orderItem.id);
    setCart((prev) => ({ ...prev, cartProducts: cartUpdate }));

    handleRemoveOutStock(Number(orderItem.data.id));
    setIsDeleteModalOpen(false);
  };

  const cancel = () => {
    setIsDeleteModalOpen(false);
    setIsDecreasePopconfirmOpen(false);
  };

  useEffect(() => {
    if (!isCartHeaderOpen) {
      setIsDeleteModalOpen(false);
      setIsDecreasePopconfirmOpen(false);
    }
  }, [isCartHeaderOpen]);

  const handleConfirmDelete = () => {
    setIsDeleteModalOpen(true);
  };

  const diffPrice = !orderItem.data.flashSalePrice
    ? Number(orderItem.data.price) - Number(orderItem.data.pricePromotion)
    : Number(orderItem.data.price) - Number(orderItem.data.flashSalePrice);
  if (!isStock && !inCart) {
    return (
      <div className="w-full flex justify-start items-center gap-[30px]">
        <div className="flex min-w-[324px]">
          <div className="relative min-w-[120px] w-[120px] min-h-[120px] h-[120px]">
            <img
              src={orderItem.data.image || images.productOne}
              alt={orderItem.data.name}
              className="w-full h-full"
            />
            <div className="absolute top-0 left-0 w-full h-full flex flex-col justify-center items-center bg-[#00000033] ">
              <p className="text-black font-[400] leading-[19.36px] text-[16px]">
                Tạm hết
              </p>
            </div>
          </div>

          <div className="flex flex-col justify-between ml-[8px] w-[196px]">
            <Link
              to={`${routes.PRODUCT}/${orderItem?.data?.slug}`}
              className="w-full"
            >
              <p className="text-[16px] font-[500] leading-[19.36px] text-[#808080] line-clamp-4">
                {orderItem.data.name}
              </p>
            </Link>
          </div>
        </div>
        <div className="min-w-[77px] flex justify-between items-center">
          <div className="p-[3px] cursor-not-allowed">
            <DecreaseQuantity />
          </div>
          <span className="mx-[4px] text-[#808080] text-[16px] font-[400] leading-[19.36px]">
            {orderItem.quantity}
          </span>
          <div className="p-[3px] cursor-not-allowed">
            <IncreaseQuantity />
          </div>
        </div>
        <div className="flex flex-col justify-start items-center">
          {!orderItem?.data?.flashSale && !orderItem?.data?.pricePromotion ? (
            <span className="text-[16px] text-black font-[500] leading-[19.36px]">
              {formatPriceToVnd(Number(orderItem.data.price))}
            </span>
          ) : (
            <div className="flex flex-col justify-start items-start">
              <span className="text-[14px] text-[#808080] font-[400] leading-[19.64px] line-through">
                {orderItem?.data?.price === orderItem?.data?.pricePromotion
                  ? ""
                  : formatPriceToVnd(Number(orderItem.data.pricePromotion))}
              </span>
              <span className="text-[16px] text-[#808080] font-[500] leading-[19.36px]">
                {formatPriceToVnd(
                  Number(
                    orderItem.data.flashSale && orderItem.data.flashSalePrice
                      ? orderItem.data.flashSalePrice
                      : orderItem.data.pricePromotion
                  )
                )}
              </span>
            </div>
          )}
        </div>

        {hasTrashIcon && (
          <div className="w-full flex justify-end">
            <div
              className="w-[48px] h-[48px] px-[13px] py-[12px] hover:bg-[#F5F5DD] hover:rounded-full cursor-pointer"
              onClick={handleConfirmDelete}
            >
              <img src={svgs.trash} className="w-full h-full" />
            </div>
          </div>
        )}
        <BasicModal
          rootClassName="confirm"
          isModalOpen={isDeleteModalOpen}
          okText="Xóa"
          cancelText="Hủy"
          handleOk={handleRemove}
          handleCancel={() => setIsDeleteModalOpen(false)}
          closeIcon={false}
          title={false}
          width={380}
        >
          <p className="text-[16px] text-[#484848] text-center">
            Bạn muốn xoá sản phẩm này khỏi giỏ hàng?
          </p>
        </BasicModal>
      </div>
    );
  }

  if (!isStock && inCart) {
    return (
      <div className="w-full flex justify-start items-center gap-[30px]">
        <div className="flex flex-1">
          <div className="relative md:w-[120px] md:h-[120px] h-[94px] w-[94px]">
            <img
              src={orderItem.data.image || images.productOne}
              alt={orderItem.data.name}
              className="w-full h-full"
            />
            <div className="absolute top-0 left-0 w-full h-full flex flex-col justify-center items-center bg-[#00000033] ">
              <p className="text-black font-[400] leading-[19.36px] text-[16px]">
                Tạm hết
              </p>
            </div>
          </div>

          <div className="flex flex-col justify-between ml-[8px] w-full">
            <Link
              to={`${routes.PRODUCT}/${orderItem?.data?.slug}`}
              className="w-full"
            >
              <p className="text-[16px] font-[500] leading-[19.36px] text-[#808080] line-clamp-2">
                {orderItem.data.name}
              </p>
            </Link>
            <div className="flex item-center justify-between">
              <div className="flex flex-col justify-start items-center md:hidden">
                {!orderItem?.data?.flashSale &&
                !orderItem?.data?.pricePromotion ? (
                  <span className="text-[16px] text-black font-[500] leading-[19.36px]">
                    {formatPriceToVnd(Number(orderItem.data.price))}
                  </span>
                ) : (
                  <div className="flex flex-col justify-start items-start">
                    <span className="text-[14px] text-[#808080] font-[400] leading-[19.64px] line-through">
                      {orderItem?.data?.price ===
                      orderItem?.data?.pricePromotion
                        ? ""
                        : formatPriceToVnd(
                            Number(orderItem.data.pricePromotion)
                          )}
                    </span>
                    <span className="text-[16px] text-[#808080] font-[500] leading-[19.36px]">
                      {formatPriceToVnd(
                        Number(
                          orderItem.data.flashSale &&
                            orderItem.data.flashSalePrice
                            ? orderItem.data.flashSalePrice
                            : orderItem.data.pricePromotion
                        )
                      )}
                    </span>
                  </div>
                )}
              </div>
              <div className="min-w-[77px] flex justify-between items-center md:hidden">
                <div className="p-[3px] cursor-not-allowed">
                  <DecreaseQuantity />
                </div>
                <span className="mx-[4px] text-[#808080] text-[16px] font-[400] leading-[19.36px]">
                  {orderItem.quantity}
                </span>
                <div className="p-[3px] cursor-not-allowed">
                  <IncreaseQuantity />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="min-w-[77px] md:flex justify-between items-center hidden">
          <div className="p-[3px] cursor-not-allowed">
            <DecreaseQuantity />
          </div>
          <span className="mx-[4px] text-[#808080] text-[16px] font-[400] leading-[19.36px]">
            {orderItem.quantity}
          </span>
          <div className="p-[3px] cursor-not-allowed">
            <IncreaseQuantity />
          </div>
        </div>
        <div className="md:flex flex-col justify-start items-center hidden">
          {!orderItem?.data?.flashSale && !orderItem?.data?.pricePromotion ? (
            <span className="text-[16px] text-black font-[500] leading-[19.36px]">
              {formatPriceToVnd(Number(orderItem.data.price))}
            </span>
          ) : (
            <div className="flex flex-col justify-start items-start">
              <span className="text-[14px] text-[#808080] font-[400] leading-[19.64px] line-through">
                {orderItem?.data?.price === orderItem?.data?.pricePromotion
                  ? ""
                  : formatPriceToVnd(Number(orderItem.data.pricePromotion))}
              </span>
              <span className="text-[16px] text-[#808080] font-[500] leading-[19.36px]">
                {formatPriceToVnd(
                  Number(
                    orderItem.data.flashSale && orderItem.data.flashSalePrice
                      ? orderItem.data.flashSalePrice
                      : orderItem.data.pricePromotion
                  )
                )}
              </span>
            </div>
          )}
        </div>

        <BasicModal
          rootClassName="confirm"
          isModalOpen={isDeleteModalOpen}
          okText="Xóa"
          cancelText="Hủy"
          handleOk={handleRemove}
          handleCancel={() => setIsDeleteModalOpen(false)}
          closeIcon={false}
          title={false}
          width={380}
        >
          <p className="text-[16px] text-[#484848] text-center">
            Bạn muốn xoá sản phẩm này khỏi giỏ hàng?
          </p>
        </BasicModal>
      </div>
    );
  }
  //products is stocked and in cart page
  if (isStock && inCart) {
    return (
      <div className="w-full flex gap-[16px] h-[120px]">
        <div className="flex gap-[8px] flex-1">
          <div className="md:w-[120px] md:h-[120px] h-[94px] w-[94px]">
            <img
              src={orderItem.data.image || images.productOne}
              alt={orderItem.data.name}
              className="w-full h-full object-cover"
            />
          </div>
          <div className="flex flex-col justify-between h-full w-full">
            <Link
              to={`${routes.PRODUCT}/${orderItem?.data?.slug}`}
              className="w-full"
            >
              <p className="text-[14px] md:text-[16px] font-[500] leading-[19.36px] text-black line-clamp-2">
                {orderItem.data.name}
              </p>
            </Link>
            <div className="flex items-center justify-between">
              <div className="md:hidden flex flex-col justify-center items-center gap-[8px]">
                {!orderItem?.data?.flashSale &&
                !orderItem?.data?.pricePromotion ? (
                  <span className="text-[16px] text-black font-[500] leading-[19.36px]">
                    {formatPriceToVnd(Number(orderItem.data.price))}
                  </span>
                ) : (
                  <div className="flex flex-col justify-start items-start">
                    <span className="text-[14px] text-[#818080] font-[400] leading-[19.64px] line-through">
                      {formatPriceToVnd(Number(orderItem.data.price))}
                    </span>
                    <span className="text-[16px] text-black font-[500] leading-[19.36px]">
                      {formatPriceToVnd(
                        Number(
                          orderItem.data.flashSale &&
                            orderItem.data.flashSalePrice
                            ? orderItem.data.flashSalePrice
                            : orderItem.data.pricePromotion
                        )
                      )}
                    </span>
                  </div>
                )}
              </div>
              <div className="min-w-[77px] flex justify-between items-center md:hidden">
                <Popconfirm
                  title=""
                  icon=""
                  description="Bạn muốn xoá sản phẩm này khỏi giỏ hàng?"
                  onConfirm={handleDecrease}
                  onCancel={cancel}
                  okText="Xoá"
                  cancelText="Huỷ"
                  open={isDecreasePopconfirmOpen}
                  rootClassName="confirm"
                ></Popconfirm>
                <div
                  className="p-[3px] cursor-pointer"
                  onClick={handleDecrease}
                >
                  <DecreaseQuantity active />
                </div>
                <span className="mx-[4px] text-[16px] text-black font-[400] leading-[19.36px] self-center">
                  {orderItem.quantity}
                </span>
                <div
                  className="p-[3px] cursor-pointer"
                  onClick={handleIncrease}
                >
                  <IncreaseQuantity active />
                </div>
              </div>
            </div>
            <div className="flex items-center">
              <div className="w-[16px] h-[16px] p-[4px]">
                <img
                  src={svgs.doubleArrowDown}
                  className="w-full h-full object-contain"
                />
              </div>
              <p className="text-[12px] leading-[14.52px] text-[#FF9900]">
                Giảm {formatPriceToEN(diffPrice)} so với giá niêm yết
              </p>
            </div>
          </div>
        </div>

        <div className="min-w-[77px] md:flex justify-between items-center hidden">
          <Popconfirm
            title=""
            icon=""
            description="Bạn muốn xoá sản phẩm này khỏi giỏ hàng?"
            onConfirm={handleDecrease}
            onCancel={cancel}
            okText="Xoá"
            cancelText="Huỷ"
            open={isDecreasePopconfirmOpen}
            rootClassName="confirm"
          ></Popconfirm>
          <div className="p-[3px] cursor-pointer" onClick={handleDecrease}>
            <DecreaseQuantity active />
          </div>
          <span className="mx-[4px] text-[16px] text-black font-[400] leading-[19.36px] self-center">
            {orderItem.quantity}
          </span>
          <div className="p-[3px] cursor-pointer" onClick={handleIncrease}>
            <IncreaseQuantity active />
          </div>
        </div>
        <div className="hidden md:flex flex-col justify-center items-center gap-[8px]">
          {!orderItem?.data?.flashSale && !orderItem?.data?.pricePromotion ? (
            <span className="text-[16px] text-black font-[500] leading-[19.36px]">
              {formatPriceToVnd(Number(orderItem.data.price))}
            </span>
          ) : (
            <div className="flex flex-col justify-start items-start">
              <span className="text-[14px] text-[#818080] font-[400] leading-[19.64px] line-through">
                {formatPriceToVnd(Number(orderItem.data.price))}
              </span>
              <span className="text-[16px] text-black font-[500] leading-[19.36px]">
                {formatPriceToVnd(
                  Number(
                    orderItem.data.flashSale && orderItem.data.flashSalePrice
                      ? orderItem.data.flashSalePrice
                      : orderItem.data.pricePromotion
                  )
                )}
              </span>
            </div>
          )}
        </div>
      </div>
    );
  }

  if (isStock && !inCart) {
    return (
      <div className="w-full flex justify-start items-center gap-[30px]">
        <div className="flex w-[324px]">
          <div className={"min-w-[120px] w-[120px] min-h-[120px] h-[120px]"}>
            <img
              src={orderItem.data.image || images.productOne}
              alt={orderItem.data.name}
              className="w-full h-full"
            />
          </div>

          <div className="flex flex-col justify-between ml-[8px] w-[196px]">
            <Link
              to={`${routes.PRODUCT}/${orderItem?.data?.slug}`}
              className="w-full"
            >
              <p className="text-[16px] font-[500] leading-[19.36px] text-black line-clamp-4">
                {orderItem.data.name}
              </p>
            </Link>

            <div>
              <p className="flex text-[10px] md:text-[12px] text-[#FF9900] leading-[14.52px] font-normal text-ellipsis line-clamp-2">
                <div className="w-[16px] h-[16px] p-[4px]">
                  <img src={svgs.doubleArrowDown} className="w-full h-full" />
                </div>
                Giảm {formatPriceToEN(diffPrice)} so với giá niêm yết
              </p>
            </div>
          </div>
        </div>
        <div className="min-w-[77px] md:flex justify-between items-start hidden">
          <Popconfirm
            title=""
            icon=""
            description="Bạn muốn xoá sản phẩm này khỏi giỏ hàng?"
            onConfirm={handleDecrease}
            onCancel={cancel}
            okText="Xoá"
            cancelText="Huỷ"
            open={isDecreasePopconfirmOpen}
            rootClassName="confirm"
          ></Popconfirm>
          <div className="p-[3px] cursor-pointer" onClick={handleDecrease}>
            <DecreaseQuantity active />
          </div>
          <span className="mx-[4px] text-[16px] text-black font-[400] leading-[19.36px] self-center">
            {orderItem.quantity}
          </span>
          <div className="p-[3px] cursor-pointer" onClick={handleIncrease}>
            <IncreaseQuantity active />
          </div>
        </div>
        <div className="flex flex-col justify-between items-center">
          {!orderItem?.data?.flashSale && !orderItem?.data?.pricePromotion ? (
            <span className="text-[16px] text-black font-[500] leading-[19.36px]">
              {formatPriceToVnd(Number(orderItem.data.price))}
            </span>
          ) : (
            <div className="flex flex-col justify-start items-start">
              <span className="text-[14px] text-[#818080] font-[400] leading-[19.64px] line-through">
                {formatPriceToVnd(Number(orderItem.data.price))}
              </span>
              <span className="text-[16px] text-black font-[500] leading-[19.36px]">
                {formatPriceToVnd(
                  Number(
                    orderItem.data.flashSale && orderItem.data.flashSalePrice
                      ? orderItem.data.flashSalePrice
                      : orderItem.data.pricePromotion
                  )
                )}
              </span>
            </div>
          )}
        </div>

        {hasTrashIcon && (
          <div className="w-full flex justify-end">
            <div
              className="w-[48px] h-[48px] px-[13px] py-[12px] hover:bg-[#F5F5DD] hover:rounded-full cursor-pointer"
              onClick={handleConfirmDelete}
            >
              <img src={svgs.trash} className="w-full h-full" />
            </div>
          </div>
        )}
        <BasicModal
          rootClassName="confirm"
          isModalOpen={isDeleteModalOpen}
          okText="Xóa"
          cancelText="Hủy"
          handleOk={handleRemove}
          handleCancel={() => setIsDeleteModalOpen(false)}
          closeIcon={false}
          title={false}
          width={380}
        >
          <p className="text-[16px] text-[#484848] text-center">
            Bạn muốn xoá sản phẩm này khỏi giỏ hàng?
          </p>
        </BasicModal>
      </div>
    );
  }
};

export default OrderCart;
