import React, { useState } from "react";
import { MenuItem, IconButton } from "@material-tailwind/react";
import svg from "@/assets/svgs";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import jsonData from "../../../navBar.json";
import { useNavigate } from "react-router-dom";
import { Drawer } from "antd";
import useHomeQueryData from "@/hooks/ReactQuery/home.query";
import routes from "@/utils/routes";
import { CategoriesTree } from "@/type/response/home";
import urls from "@/utils/urls";
import constants from "@/utils/constants";

type TNav = {
  label: string;
  url?: string;
  child?: TNav[];
  needAuth?: boolean;
};

interface IDrawerComponentProps {
  setIsOpenSearchDrawer: (isOpenSearchDrawer: boolean) => void;
}

const hasHighlight = (pathname: string) => {
  return location.pathname === pathname;
};

export default function DrawerComponent({
  setIsOpenSearchDrawer,
}: IDrawerComponentProps) {
  const [open, setOpen] = React.useState(false);

  const openDrawer = () => {
    setOpen(true);
    setIsOpenSearchDrawer(false);
  };
  const closeDrawer = () => {
    setOpen(false);
    setIsOpenSearchDrawer(false);
  };
  const navData = jsonData["dataMobile"];

  return (
    <React.Fragment>
      <div className="w-[24px] h-[24px]" onClick={openDrawer}>
        <img src={svg.bar} alt="logo" className="h-full w-full object-cover" />
      </div>

      <Drawer
        open={open}
        onClose={closeDrawer}
        className="z-10"
        placement="left"
        styles={{
          header: { display: "none" },
          body: { padding: 0 },
          content: {
            borderTopRightRadius: "24px",
            borderBottomRightRadius: "24px",
          },
        }}
        width={315}
        rootStyle={{
          borderTopRightRadius: "24px",
          borderBottomRightRadius: "24px",
          zIndex: 9999,
        }}
        style={{
          borderTopRightRadius: "24px",
          borderBottomRightRadius: "24px",
        }}
      >
        <div className="h-screen overflow-auto">
          <div className="flex items-center justify-between p-[16px] border-b border-b-[#E2DCCE]">
            <p className="text-[16px] font-[400] text-[#000]">Menu</p>
            <IconButton
              variant="text"
              color="blue-gray"
              onClick={closeDrawer}
              placeholder=""
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={2}
                stroke="currentColor"
                className="h-[24px] w-[24px]"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </IconButton>
          </div>

          <div className="px-[16px]">
            {navData.map((i) => (
              <RenderNav
                nav={i}
                isRoot={true}
                key={i.label}
                onClose={closeDrawer}
              />
            ))}
          </div>
        </div>
      </Drawer>
    </React.Fragment>
  );
}

const RenderNavCategoriesTree = ({
  category,
  isRoot = false,
  selectedCategory,
  setSelectedCategory,
  onClose,
}: {
  category: CategoriesTree;
  isRoot: boolean;
  onClose: () => void;
  selectedCategory: number;
  setSelectedCategory: (value: number) => void;
}) => {
  const [openChild, setOpenChild] = React.useState(false);

  const navigate = useNavigate();

  const onExpand = () => {
    setOpenChild(!openChild);
  };

  if (!category.children?.length) {
    return (
      <MenuItem
        placeholder={""}
        key={category.name}
        className="flex text-[16px] mt-[16px] font-normal"
        onClick={() => {
          setSelectedCategory(category.id);
          navigate(`${routes.PRODUCT}?${urls.CATEGORY}=${category.id}`);
          onClose();
        }}
      >
        {isRoot ? (
          category.name
        ) : (
          <span
            className={clsx(
              "text-[16px]",
              selectedCategory === category.id
                ? "text-[#FF9900]"
                : "text-inherit"
            )}
          >
            {category.name}
          </span>
        )}
      </MenuItem>
    );
  }

  if (category.children?.length && category.children) {
    return (
      <div key={category.name}>
        <MenuItem
          placeholder={""}
          className={clsx(
            " flex justify-between items-center text-[16px] cursor-pointer mt-[16px] pb-0 ",
            openChild && "text-[#FF9900]"
          )}
        >
          {isRoot ? (
            <span
              className={clsx(
                " flex justify-between items-center text-[16px] cursor-pointer ",
                openChild && "text-[#FF9900]"
              )}
              onClick={() => {
                setSelectedCategory(category.id);
                navigate(`${routes.PRODUCT}?${urls.CATEGORY}=${category.id}`);
                onClose();
              }}
            >
              {category.name}
            </span>
          ) : (
            <span
              className={clsx(
                " flex justify-between items-center text-[16px] cursor-pointer ",
                openChild && "text-[#FF9900]"
              )}
              onClick={() => {
                setSelectedCategory(category.id);
                navigate(`${routes.PRODUCT}?${urls.CATEGORY}=${category.id}`);
                onClose();
              }}
            >
              {category.name}
            </span>
          )}
          <ChevronRightIcon
            strokeWidth={2.5}
            className={`h-3.5 w-3.5 transition-transform ${
              openChild ? "rotate-90" : ""
            }`}
            onClick={onExpand}
          />
        </MenuItem>

        <div className={clsx(openChild ? "block" : " hidden", "pl-4 py-1")}>
          {category.children.map((i) => (
            <RenderNavCategoriesTree
              category={i}
              isRoot={false}
              key={i.name}
              selectedCategory={selectedCategory}
              setSelectedCategory={setSelectedCategory}
              onClose={onClose}
            />
          ))}
        </div>
      </div>
    );
  }
};

const RenderNav = ({
  nav,
  isRoot = false,
  onClose,
}: {
  nav: TNav;
  isRoot: boolean;
  onClose: () => void;
}) => {
  const [openChild, setOpenChild] = React.useState(false);
  const [selectedCategory, setSelectedCategory] = useState<number>(-1);
  const { categoriesTree } = useHomeQueryData();

  const isLoggedIn = localStorage.getItem(constants.ACCESS_TOKEN);
  const navigate = useNavigate();

  const onLinkto = (path?: string) => {
    navigate(path as string);
    onClose();
  };

  const showLogin = nav.url === routes.LOGIN && !!isLoggedIn === false;
  const showLogout =
    nav.url === routes.LOG_OUT &&
    nav?.needAuth === true &&
    !!isLoggedIn === true;
  if (nav?.url === routes.PRODUCT) {
    return (
      <div key={nav.label}>
        <MenuItem
          placeholder={""}
          className={clsx(
            " flex justify-between items-center text-[16px] cursor-pointer mt-[16px] pb-0 ",
            openChild && "text-[#FF9900]"
          )}
        >
          {isRoot ? (
            <span
              className={clsx(
                "flex justify-between items-center text-[16px] cursor-pointer",
                openChild && "text-[#FF9900]"
              )}
              onClick={() => {
                navigate(routes.PRODUCT);
                onClose();
              }}
            >
              {nav.label}
            </span>
          ) : (
            <span
              className={clsx(
                "flex justify-between items-center text-[16px] cursor-pointer",
                openChild && "text-[#FF9900]"
              )}
            >
              {nav.label}
            </span>
          )}

          <ChevronRightIcon
            strokeWidth={2.5}
            className={`h-3.5 w-3.5 transition-transform ${
              openChild ? "rotate-90" : ""
            }`}
            onClick={() => {
              setOpenChild(!openChild);
            }}
          />
        </MenuItem>

        <div className={clsx(openChild ? "block" : " hidden", "pl-4 py-1")}>
          {categoriesTree?.map((category) => (
            <RenderNavCategoriesTree
              category={category}
              isRoot={false}
              key={category.name}
              selectedCategory={selectedCategory}
              setSelectedCategory={setSelectedCategory}
              onClose={onClose}
            />
          ))}
        </div>
      </div>
    );
  }

  if (showLogout)
    return (
      <MenuItem
        placeholder={""}
        key={nav.label}
        className={clsx("flex text-[16px] mt-[16px] pb-0 font-normal", {
          "text-[#FF9900]": hasHighlight(nav?.url || ""),
        })}
        onClick={() => {
          if (nav.url === routes.LOG_OUT) {
            localStorage.clear();
            window.dispatchEvent(new Event("storage"));
            navigate(0);
            window.location.replace(routes.LOGIN);
            return;
          }
          onLinkto(nav?.url);
        }}
      >
        {nav.label}
      </MenuItem>
    );

  if (showLogin)
    return (
      <MenuItem
        placeholder={""}
        key={nav.label}
        className={clsx("flex text-[16px] mt-[16px] pb-0 font-normal", {
          "text-[#FF9900]": hasHighlight(nav?.url || ""),
        })}
        onClick={() => {
          if (nav.url === routes.LOG_OUT) {
            localStorage.clear();
            window.dispatchEvent(new Event("storage"));
            navigate(0);
            window.location.replace(routes.LOGIN);
            return;
          }
        }}
      >
        {nav.label}
      </MenuItem>
    );
  if (
    !nav.child?.length &&
    nav.url !== routes.LOGIN &&
    nav.url != routes.LOG_OUT
  ) {
    return (
      <MenuItem
        placeholder={""}
        key={nav.label}
        className={clsx(
          "text-[16px] mt-[16px] pb-0 font-normal",
          {
            "text-[#FF9900]": hasHighlight(nav?.url || ""),
          },
          !showLogin && !showLogout ? "flex" : "hidden"
        )}
        onClick={() => {
          onLinkto(nav?.url);
        }}
      >
        {nav.label}
      </MenuItem>
    );
  }

  if (nav.child?.length && nav.child) {
    return (
      <div key={nav.label}>
        <MenuItem
          placeholder={""}
          className={clsx(
            " flex justify-between items-center text-[16px] cursor-pointer mt-[16px] pb-0 ",
            openChild && "text-[#FF9900]"
          )}
        >
          {nav.label}
          <ChevronRightIcon
            strokeWidth={2.5}
            className={`h-3.5 w-3.5 transition-transform ${
              openChild ? "rotate-90" : ""
            }`}
            onClick={() => {
              setOpenChild(!openChild);
            }}
          />
        </MenuItem>

        <div className={clsx(openChild ? "block" : " hidden", "pl-4 py-1")}>
          {nav.child.map((i) => (
            <RenderNav
              nav={{ ...i, url: `${nav.url ?? ""}${i.url ?? ""}` }}
              isRoot={false}
              key={i.label}
              onClose={onClose}
            />
          ))}
        </div>
      </div>
    );
  }
};
