/* eslint-disable react/jsx-boolean-value */
/* eslint-disable import/extensions */
import { Loading } from "@/components";
// import CarouselComponent from '@/components/common/Carousel';
// import FlashSale from '@/components/common/FlashSale';
import ModelVoucher from "@/components/common/ModelVoucher";
import QuickFilterComponent from "@/components/common/QuickFilter";
import SectionVertical from "@/components/common/SectionVertical";
// import BannerHomeComponent from '@/components/pages/Home/Banner';
// import KolVerticalComponent from '@/components/pages/Home/Kol';
// import PartnerComponent from '@/components/pages/Home/Partner';
import VouchersMember from "@/components/pages/Home/VoucherMember";
import useHomeQueryData from "@/hooks/ReactQuery/home.query";
import loadingScreenState from "@/recoil/loadingScreenState";
import constants from "@/utils/constants";
import routes from "@/utils/routes";
import { Suspense, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { lazy } from "react";
import useProductsQuery from "@/hooks/ReactQuery/products.query";
import clsx from "clsx";
import svgs from "@/assets/svgs";
import NewProduct from "@/components/common/NewProduct";
import { motion } from "framer-motion";
import { useRootLayoutContext } from "@/layouts/RootLayout";
import useWindowSize from "@/hooks/useWindowSize";

const CarouselComponent = lazy(() => import("@/components/common/Carousel"));
// const BannerHomeComponent = lazy(() => import('@/components/pages/Home/Banner'));
const FlashSale = lazy(() => import("@/components/common/FlashSale"));

const animationSettings = {
  initial: { opacity: 0, scale: 0.7 },
  whileInView: { opacity: 1, scale: 1 },
  transition: {
    type: "spring",
    duration: 1,
    delay: 0.1,
    ease: [0, 0.71, 0.2, 1.01],
  },
  viewport: { once: true }, // Ensures the animation runs only once
};

export default function Home() {
  const navigate = useNavigate();
  const handleSetIsLoading = useSetRecoilState(loadingScreenState);
  const { setIsLoadingDone } = useRootLayoutContext();
  const size = useWindowSize();
  const [selectedBrandSaleId, setSelectedBrandSaleId] = useState<number>(-1);
  // const [routineHovered, setRoutineHovered] = useState<number>(-1);

  // model show voucher for home page, set default is false because, now we don't have any voucher
  const isLoggedIn = localStorage.getItem(constants.ACCESS_TOKEN);
  const isOpenModelVoucher = isLoggedIn
    ? false
    : localStorage.getItem("openModelVoucher") === "false"
      ? false
      : true;

  const [openModelVoucher, setOpenModelVoucher] =
    useState<boolean>(isOpenModelVoucher);

  function handleModelVoucher(value: boolean) {
    localStorage.setItem("openModelVoucher", value.toString());
    handleOpenModal(value);
    navigate(routes.REGISTER);
  }

  const handleOpenModal = (value: boolean) => {
    localStorage.setItem("openModelVoucher", value.toString());
    setOpenModelVoucher(value);
  };

  const {
    blocks,
    // firstTwoBlocks,
    isLoading,
    // parentQuick,
    // bannerBrand,
    bannerSale,
    mobileBanner,
    // blockIsEmpty,
    // moreBlocks,
    // KolData,
    // partners,
    programmesData,
    // routineBlock,
  } = useHomeQueryData();
  const productsSort = [
    "total_purchase:desc",
    "price_promotion:desc",
    "name:asc",
  ];
  const { products } = useProductsQuery(
    productsSort.map((sort, index) => `sort[${index}]=${sort}`).join("&")
  );

  useEffect(() => {
    if (!isLoading) {
      setIsLoadingDone(true);
      setSelectedBrandSaleId(Number(programmesData?.data?.[0]?.id));
    }
  }, [isLoading, programmesData]);

  useEffect(() => {
    handleSetIsLoading(true);
  }, []);

  if (isLoading) return <Loading />;

  // const QUICK_FILTER_1 =
  //   parentQuick != undefined ? parentQuick.quickFilter1 : [];
  // const QUICK_FILTER_2 =
  //   parentQuick != undefined ? parentQuick?.quickFilter2 : [];

  return (
    <div className="flex flex-col gap-[8px] bg-[#F7F7F7]">
      <section className="bg-[#FFF]">
        <Suspense fallback={<Loading />}>
          <CarouselComponent
            bannerSales={size?.width < 768 ? mobileBanner : bannerSale}
          />
        </Suspense>
      </section>
      <section className="bg-[#FFF]">
        <QuickFilterComponent />
      </section>
      <section className="bg-[#FFF]">
        <Suspense fallback={<Loading />}>
          <FlashSale />
        </Suspense>
      </section>
      {/* <section className="section__banner-brand">
        <Suspense fallback={<Loading />}>
          {bannerBrand && <BannerHomeComponent bannerBrand={bannerBrand} />}
        </Suspense>
      </section> */}

      <section className="bg-[#FFF]">
        <div className="md:max-w-[1440px] w-full mx-auto">
          <motion.div
            className="my-[24px] md:my-[32px] w-full px-[16px] md:px-[64px] flex flex-col gap-[16px] md:gap-[32px]"
            {...animationSettings}
          >
            {products?.length && (
              <SectionVertical
                title={"Bán chạy"}
                products={
                  products
                    .sort(
                      (a, b) =>
                        b?.attributes?.totalPurchase -
                        a?.attributes?.totalPurchase
                    )
                    .slice(0, 10) ?? []
                }
                useSpecialName
                slidePreviewMobile={2.5}
                showThumbLabel
              />
            )}
          </motion.div>
        </div>
      </section>

      <section className="bg-[#FFF]">
        <div className="w-full max-w-[1440px] mx-auto py-[24px] md:py-[32px] px-[16px] md:px-[64px] lg:px-[160px]">
          <p className="text-[20px] md:text-[28px] font-[600] md:uppercase">
            Deal từ thương hiệu
          </p>
          <div className="hidden md:grid md:grid-cols-12 gap-[24px] ">
            {programmesData?.data?.length !== 0 &&
              programmesData?.data?.slice(0, 2).map((program, index) => (
                <motion.div
                  key={index}
                  className="col-span-6"
                  {...animationSettings}
                >
                  <SectionVertical
                    program={program}
                    key={index}
                    products={
                      program?.attributes?.products?.data?.slice(0, 5) ?? []
                    }
                    useSpecialName
                    slidePreviewDesktop={2.5}
                    slidePreviewMobile={2.5}
                    hasBanner
                  />
                </motion.div>
              ))}
          </div>
          <div className="flex md:hidden gap-[8px] items-center mt-[16px]">
            {programmesData?.data?.map((program, index) => (
              <div
                key={index}
                className={clsx(
                  "py-[12px] flex items-center justify-center rounded-[24px] flex-1 cursor-pointer transition-all",
                  {
                    "border border-[#000]":
                      selectedBrandSaleId === Number(program?.id),
                  }
                )}
                onClick={() => setSelectedBrandSaleId(Number(program?.id))}
              >
                <p className="text-[14px] font-[600] text-[#000]">
                  {program?.attributes?.name}
                </p>
              </div>
            ))}
          </div>
          <div className="flex md:hidden items-center mt-[12px] overflow-hidden">
            <motion.div
              className={clsx(
                "w-full duration-300",
                selectedBrandSaleId === programmesData?.data?.[0]?.id
                  ? "translate-x-0"
                  : "translate-x-[-100%]"
              )}
              {...animationSettings}
            >
              <SectionVertical
                program={programmesData?.data?.[0]}
                products={
                  programmesData?.data?.[0]?.attributes?.products?.data?.slice(
                    0,
                    5
                  ) ?? []
                }
                useSpecialName
                hasBanner
              />
            </motion.div>
            <motion.div
              className={clsx(
                "w-full duration-300",
                selectedBrandSaleId === programmesData?.data?.[1]?.id
                  ? "translate-x-[-100%] "
                  : "translate-x-0"
              )}
              {...animationSettings}
            >
              <SectionVertical
                program={programmesData?.data?.[1]}
                products={
                  programmesData?.data?.[1]?.attributes?.products?.data?.slice(
                    0,
                    5
                  ) ?? []
                }
                useSpecialName
                hasBanner
              />
            </motion.div>
          </div>
        </div>
      </section>

      <section className="bg-[#FFF]">
        {!!blocks?.length &&
          blocks.slice(1, 3).map((block, index) => (
            <div
              className="w-full max-w-[1440px] mx-auto py-[24px] md:py-[32px] px-[16px] md:px-[64px] lg:px-[160px]"
              key={index}
            >
              <motion.div
                className="flex flex-col gap-[16px] md:gap-[24px]"
                {...animationSettings}
              >
                <div
                  className="w-fit flex items-center gap-[10px] md:gap-[4px] md:pb-[8px] cursor-pointer"
                  onClick={() => {
                    const categoryID = block?.category?.data?.id;

                    if (!categoryID) return;

                    navigate(`${routes.PRODUCT}?categories=${categoryID}`);
                  }}
                >
                  <p className="text-[20px] md:text-[28px] font-[600] uppercase">
                    {block?.title}
                  </p>
                  <img
                    src={svgs.arrowLeftBlack}
                    alt=""
                    className="rotate-180 p-[10px] md:p-[16px] size-[32px] md:size-[48px]"
                  />
                </div>
                <img
                  src={block?.banner?.data?.attributes?.url}
                  alt=""
                  className="w-full rounded-[24px_24px_0_0] h-[126px] md:h-[261px] object-cover"
                />
                <SectionVertical
                  slidePreviewDesktop={5}
                  products={
                    block?.category?.data?.attributes?.products?.data ?? []
                  }
                  useSpecialName
                  slidePreviewMobile={2.5}
                />
              </motion.div>
            </div>
          ))}
      </section>

      <section className="bg-[#F0F0F0]">
        <VouchersMember />
      </section>

      {/* <section className="bg-[#FFFFFF]">
        <div
          className="w-full max-w-[1440px] mx-auto py-[24px] md:py-[32px] px-[16px] md:px-[64px] lg:px-[160px]"
        >
          <div className="flex flex-col gap-[32px]">
            <p className="text-[30px] font-[600] uppercase">
              Routine
            </p>
            <div
              className="rounded-[16px] grid grid-cols-2 grid-rows-2"
              onMouseLeave={() => setRoutineHovered(-1)}
            >
              {routineBlock?.map((routine, index) => (
                <div
                  className="relative"
                  key={index}
                  onMouseEnter={() => setRoutineHovered(index)}
                >
                  <img
                    src={routine?.banner?.data?.attributes?.url}
                    alt=""
                    className={clsx(
                      "w-full h-[300px] object-cover object-center",
                      {
                        "rounded-tl-[16px]": index === 0,
                        "rounded-tr-[16px]": index === 1,
                        "rounded-bl-[16px]": index === 2,
                        "rounded-br-[16px]": index === 3,
                      },
                    )}
                  />
                  <div className={clsx(
                    "absolute z-10 bg-[#000000] opacity-20 top-0 left-0 w-full h-full transition-all ease-linear duration-300",
                    {
                      "rounded-tl-[16px]": index === 0,
                      "rounded-tr-[16px]": index === 1,
                      "rounded-bl-[16px]": index === 2,
                      "rounded-br-[16px]": index === 3,
                    },
                    routineHovered === -1 ? "hidden" : routineHovered === index ? "hidden" : "block"
                  )}>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section> */}

      <section className="bg-[#FFFFFF]">
        <div className="w-full max-w-[1440px] mx-auto py-[24px] md:py-[32px] px-[16px] md:px-[64px] lg:px-[160px]">
          <motion.div
            className="flex flex-col gap-[24px] md:gap-[32px]"
            {...animationSettings}
          >
            <p className="text-[20px] md:text-[30px] font-[600] leading-[24px] md:leading-[42px]">
              Dành cho bạn
            </p>
            <div className="hidden md:grid md:grid-cols-4 xl:grid-cols-5 grid-rows-2 gap-[24px]">
              {blocks?.[blocks?.length - 1]?.products?.data
                ?.slice(0, 10)
                .map((product, index) => {
                  return (
                    <NewProduct
                      product={product}
                      thumbType={"primary"}
                      contentPage={"home"}
                      key={index}
                    />
                  );
                })}
            </div>
            <div className="grid md:hidden grid-cols-2 grid-rows-3 gap-[16px]">
              {blocks?.[blocks?.length - 1]?.products?.data
                ?.slice(0, 6)
                .map((product, index) => {
                  return (
                    <NewProduct
                      product={product}
                      thumbType={"primary"}
                      contentPage={"home"}
                      key={index}
                    />
                  );
                })}
            </div>
          </motion.div>
        </div>
      </section>

      {/* <section className="section_kol md:container md:max-w-[1280px] md:mx-auto">
        {KolData && (<>
          <hr className="mb-8 md:mb-16 text-gray-light-1" />
          <KolVerticalComponent kolsData={KolData} />
          <hr className="mt-8 md:mt-16 text-gray-light-1" /></>
        )}
      </section>
      <section className="my-8 md:my-16 mx-4 md:container md:w-[1280px] md:mx-auto">
        <PartnerComponent isLoading={isLoading} partners={partners} />
      </section> */}
      <ModelVoucher
        title="Đăng kí thành viên So’Natural"
        subTitle="Nhận ngay voucher"
        voucherValue="300000"
        setOpen={handleOpenModal}
        handleModelVoucher={handleModelVoucher}
        open={openModelVoucher}
      />
    </div>
  );
}
