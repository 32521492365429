import VoucherSkeleton from "@/components/skeleton/common/voucher";
import { useProfileLayoutContext } from "@/layouts/ProfileLayout";
import selectedVouchersState from "@/recoil/selectedVoucherState";
import { IVoucherState } from "@/recoil/selectedVoucherState";
import cartSelector from "@/recoil/selectors/cartSelector";
import { BaseData } from "@/type/base/baseData";
import { UserVouchers } from "@/type/response/profile";
import routes from "@/utils/routes";
import { compareDates, getRemainingDays } from "@/utils/time";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";

interface VoucherListProps {
  title: string;
  description: string;
  expiryDate: Date | string;
  code: string;
  handleSubmit: () => void;
}

const VoucherList = ({
  handleSubmit,
  title,
  description,
  expiryDate,
  code,
}: VoucherListProps) => {
  return (
    <div className="list-none flex flex-col gap-[12px] md:gap-[19px]">
      <div>
        <div className="flex items-center min-h-full h-[75px] md:h-[117px] w-full border-[#E6E6E6] border-[1px] overflow-hidden rounded-[8px] md:rounded-[16px]">
          <div className="px-[8px] md:p-[4px_16px] bg-[#f0f0f0] min-h-full flex items-center justify-center w-[107px]">
            <p className="uppercase font-medium text-[12px] md:text-[13px] leading-[18.2px] truncate">
              {code}
            </p>
          </div>

          <div className="flex flex-1 flex-col p-[8px] md:p-[16px] h-full">
            <div className="w-full flex flex-col gap-[8px] md:gap-[16px]">
              <h4 className="md:font-medium text-[14px] font-[600] md:text-[16px] leading-[19.36px] m-0 line-clamp-1 md:block h-[20px]">
                {title}
              </h4>
              <div className="flex flex-col md:gap-[16px] gap-[4px]">
                <p className="text-[12px] md:text-[14px] leading-[16.94px] text-[#545454] line-clamp-1 md:block h-[20px]">
                  {description}
                </p>
                <div
                  className={`flex items-center ${
                    expiryDate !== null ? "justify-between" : "justify-end"
                  }`}
                >
                  {expiryDate !== null && (
                    <p className="text-[12px] md:text-[14px] leading-[16.94px] text-[#545454]">
                      Hạn sử dụng: {getRemainingDays(expiryDate.toString())}
                    </p>
                  )}
                  <button
                    className="text-[#FF9900] text-[12px] md:text-[14px] leading-[16.94px] text-nowrap"
                    onClick={handleSubmit}
                  >
                    Sử dụng
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function Vouchers() {
  const { allVouchers, isQueryUserVouchersLoading } = useProfileLayoutContext();
  const navigate = useNavigate();
  console.log(allVouchers);

  const { totalNumberProducts } = useRecoilValue(cartSelector);
  const setSelectedVoucher = useSetRecoilState<IVoucherState[]>(
    selectedVouchersState
  );
  const today = new Date();
  const myVoucherList = allVouchers.filter(
    (item) =>
      item?.attributes?.status === "UNUSED" &&
      (compareDates(
        item?.attributes?.voucher?.data?.attributes?.expiryDate,
        today.toISOString()
      ) >= 0 ||
        getRemainingDays(
          item?.attributes?.voucher?.data?.attributes?.expiryDate.toString()
        ) ||
        item?.attributes?.voucher?.data?.attributes?.expiryDate === null)
  );

  const handleUseMyVoucher = (voucher: BaseData<UserVouchers>) => {
    if (!totalNumberProducts) {
      toast.error("Chưa có sản phẩm trong giỏ hàng");
      return;
    }

    const {
      amountDecrease,
      code,
      percentDecrease,
      status,
      title,
      minimumOrderAmount,
      condition,
    } = voucher?.attributes?.voucher?.data?.attributes;

    const selectedVoucher: IVoucherState = {
      voucherId: voucher?.id,
      amountDecrease: amountDecrease,
      code: code,
      percentDecrease: percentDecrease,
      status: status,
      title: title,
      minimumOrderAmount: minimumOrderAmount,
      condition,
    };

    setSelectedVoucher((prev) => [...prev, selectedVoucher]);
    toast.success("Đã áp dụng voucher");
    navigate(routes.CART);
  };

  if (isQueryUserVouchersLoading) {
    return (
      <div className="flex flex-col gap-2">
        {Array.from({ length: 3 }).map((_, index) => (
          <VoucherSkeleton key={index} />
        ))}
      </div>
    );
  }
  const uniqueVouchers = myVoucherList.reduce(
    (acc: BaseData<UserVouchers>[], current) => {
      const voucherId = current.attributes.voucher.data.id;

      if (
        !acc.some((voucher) => voucher.attributes.voucher.data.id === voucherId)
      ) {
        acc.push(current);
      }

      return acc;
    },
    []
  );
  if (!myVoucherList.length) {
    return (
      <p className="font-normal text-[16px] mb-4 block text-center">
        Không có Voucher
      </p>
    );
  }

  return (
    <div className="min-h-[100vh] md:p-[24px_0_16px_24px] flex flex-col gap-[32px]">
      <h3 className="font-medium text-[20px] leading-[28px] m-0 md:block hidden">
        Voucher của tôi
      </h3>
      <div className="flex flex-col gap-[8px] mt-[24px] md:m-0">
        {uniqueVouchers.map((item, index) => {
          const voucherData = item?.attributes?.voucher?.data?.attributes;
          return (
            <VoucherList
              handleSubmit={() => handleUseMyVoucher(item)}
              key={index}
              title={voucherData?.title}
              description={voucherData?.description}
              code={voucherData?.code}
              expiryDate={voucherData?.expiryDate}
            />
          );
        })}
      </div>
    </div>
  );
}

export default Vouchers;
